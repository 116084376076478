import React from "react";

import { Link } from "react-router-dom";

import instagram from "../images/instagram.svg";
import facebook from "../images/facebook.svg";
import pinterest from "../images/pinterest.svg";
import deviant from "../images/deviantart.svg";
import behance from "../images/behance.svg";
import qrCode from "../images/qr-code.png";
import flipButton from "../images/flip-icon.svg";
import profile from "../images/profile.png";
import logo from "../images/logo.png";

// using hook for media query
import useMediaQuery from "./UseMediaQuery";

import "../App.css";

function CardBack() {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const logo_too_big_end = useMediaQuery("(min-width: 882px)");
  const logo_too_big_start = useMediaQuery("(min-width: 768px)");
  const size_for_qr_code = useMediaQuery("(min-width: 475px)");
  const isMobile = useMediaQuery("(max-width: 1024px)");
  let logo_spacing = "";
  var logo_size = 0;
  if (logo_too_big_start && !logo_too_big_end) {
    logo_size = 30;
    logo_spacing = "90px";
  } else {
    logo_size = 50;
    logo_spacing = "110px";
  }
  const desktopButton = function () {
    return (
      <div className="flex flex-col  p-2">
        <div
          className="absolute  bottom-0 right-0"
          style={{ marginBottom: "-30px", marginRight: "-25px" }}
        >
          <button className="">
            <Link to="/">
              <div className="flex h-10 rounded-lg ">
                <div
                  style={{ backgroundColor: "#CA6D81" }}
                  className=" h-12 w-12  rounded-lg"
                >
                  <img
                    className="w-8 mx-auto m-2"
                    src={flipButton}
                    alt="icon with arrow"
                  />
                </div>
              </div>
            </Link>
          </button>
        </div>
      </div>
    );
  };

  const mobileButton = function () {
    return (
      <div className="flex flex-col items-end pt-10">
        <div>
          <button className="">
            <Link to="/">
              <div className="flex h-10 rounded-lg ">
                <div
                  style={{ backgroundColor: "#CA6D81" }}
                  className=" h-12 w-12  rounded-lg"
                >
                  <img
                    className="w-8 mx-auto m-2"
                    src={flipButton}
                    alt="icon with arrow"
                  />
                </div>
              </div>
            </Link>
          </button>
        </div>
      </div>
    );
  };
  const desktop_contact = function () {
    return(
    <div className="flex flex-row border-2 border-gray max-h-[250px]  gap-8 border-gray-300 px-1 items-center py-20">
      <div className="ml-5 hover p-3">
        <img height={200} width={200} src={qrCode} alt="qr code" />
      </div>
      <div className="">
        <h1 className="font-bold text-lg lg:text-2xl">Jane Smith</h1>
        <p className="text-sm lg:text-xl">janesmith@example.com</p>
        <p className="pt-5 lg:text-xl">
          Scan QR code to checkout my website for more information!
        </p>
      </div>
    </div>
    );
  };
  const mobile_contact = function () {
    return(
    <div className="flex flex-col w-full items-center border py-5">

      <div className="flex flex-row hover">
        <img height={125} width={225} src={qrCode} alt="qr code" />
      </div>
    </div>
    );
  };
  return (
    <div>
      <div className="grid grid-cols-12 gap-3 md:gap-6 py-5  items-center">
        <div className="col-span-12 md:col-start-3 md:col-span-3 lg:col-start-3 lg:col-span-3 flex items-center relative">
          <img
            src={profile}
            className="w-1/4 md:w-1/2 lg:w-1/2 xl:w-1/3 mx-auto"
            style={{ borderRadius: "50%", border: "solid 3px white" }}
            alt="headshot"
          />
          {/* <div className="flex justify-center w-full  z-1 bottom-0 absolute">
            <img
              src={logo}
              height={logo_size}
              width={logo_size}
              alt="logo"
              style={{ marginLeft: "90px" }}
            />
          </div> */}
        </div>
        <div className="col-span-12 md:col-start-6 md:col-span-5 lg:col-span-6 lg:col-start-6 flex flex-col lg:flex-row md:gap-3 lg:gap-0">
          <div className="flex flex-col text-center md:text-left">
            <h1 className="text-4xl md:text-2xl lg:text-3xl font-bold text-salmon">
              Jane Smith
            </h1>
            <h2 className="text-4xl md:text-2xl lg:text-3xl text-salmon">
              Floral Designer
            </h2>
          </div>
        </div>
      </div>
      <div>
        <div
          className={`block lg:flex lg:justify-around  bg-white shadow-xl mx-auto p-5 md:w-2/3 lg:w-11/12 relative
          ${isDesktop ? "rounded-xl mb-10" : ""}`}
        >
          <p className="hide text-center pb-10">All my social handles</p>
          <div className="flex flex-col  items-start text-salmon gap-8 ml-10 lg:ml-0 pb-10">
            <div className="flex flex-row hover p-1 lg:pr-10 xl:pr-16 gap-10 lg:gap-3">
              <img className="w-10" src={instagram} alt="social" />
              <p className="lg:text-2xl">Instagram/janesmith</p>
            </div>
            <div className="flex flex-row hover p-1 lg:pr-14 mr-10 xl:pr-16 gap-10 lg:gap-3">
              <img className="w-10" src={facebook} alt="social" />
              <p className="lg:text-2xl">Facebook/janesmith</p>
            </div>
            <div className="flex flex-row hover p-1 lg:pr-10 xl:pr-16 gap-10 lg:gap-3">
              <img className="w-10" src={pinterest} alt="social" />
              <p className="lg:text-2xl">Pinterest/janesmith</p>
            </div>
            <div className="flex flex-row hover p-1 lg:pr-10 xl:pr-16 gap-10 lg:gap-3">
              <img className="w-10" src={deviant} alt="social" />
              <p className="lg:text-2xl">deviantart/janesmith</p>
            </div>
            <div className="flex flex-row hover p-1 lg:pr-10 xl:pr-16 gap-10 lg:gap-3">
              <img className="w-10" src={behance} alt="social" />
              <p className="lg:text-2xl">behance/janesmith</p>
            </div>
          </div>

          {isDesktop ? desktop_contact() : mobile_contact()}
          {isDesktop ? desktopButton() : mobileButton()}
        </div>
      </div>
    </div>
  );
}

export default CardBack;
