import React from "react";
import useMediaQuery from "./UseMediaQuery";
import "../NewApp.css";
function Container(props) {
    const isDesktop = useMediaQuery("(min-width: 1024px)");
    return(
        <div
            className={`cardBackground lg:rounded-lg lg:border-2 lg:border-white border-2
            ${isDesktop ? "mx-20 min-h-[800px]" : ""}`}
        >
          {props.children}

        </div>
    );
}

export default Container;