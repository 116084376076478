import { Routes, Route } from "react-router-dom";

import CardFront from "./components/CardFront";
import CardBack from "./components/CardBack";
import Container from "./components/Container";
// import "./NewApp.css";
function App() {

  const root = document.getElementById("root")
  return (
    <div className="lg:pt-2">
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <Container>
                <CardFront />
              </Container>
            }
          />
          <Route
            path="/Back"
            element={
              <Container>
                <CardBack />
              </Container>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
